.Contact {
	font-size: 1.2rem;
}

.row-contact > .col-lg {
	min-height: 70vh;
}

.container-contact {
	padding: 3.5rem 0;

	background-image: url(../images/IMG_2039_edit.jpg);
	background-size: cover;
	background-position: 50% 80%;

	color: white;
	text-align: center;
	/* height: 100%; */
	/* display: table; */
}

.container-contact p {
	padding-bottom: 0.5rem;
}

.table-schedule {
	/* max-width: 250px; */
	color: white;
	text-align: left;
}

.table-schedule td {
	padding: 0.1rem;
}

.table-schedule tr > td:nth-of-type(2) {
	text-align: right;
}