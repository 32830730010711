/* .loader {
	background-color: black;
} */



.loader {
	height: 80vh;
	width: 100vw;
	position: relative;
}

@media only screen and (max-width: 575px) {
	.loader {
		height: 95vh;
	}
}

.loader > .row {
	text-align: center;
}

.loaderimg {
	
	background-size: cover;
	background-position: center 10%;

	animation-name: slidedown;
	animation-duration: .75s;
	animation-timing-function: ease;
	animation-fill-mode: backwards;

	overflow: hidden;
	white-space: nowrap;

	transform: scale(1);
	transition: transform 400ms ease;
}

/* div.loaderimg:hover {
	transform: scale(0.96);
} */

.loaderimg:first-of-type {
	background-image: url(../images/IMG_1273_edit.jpg);
	/* z-index: 1; */
}
.loaderimg:nth-of-type(2) {
	background-image: url(../images/IMG_7344_edit.jpg);
	animation-delay: 150ms;
	/* z-index: 2; */
}
.loaderimg:nth-of-type(3) {
	background-image: url(../images/IMG_7097_edit.jpg);
	animation-delay: 300ms;
	/* z-index: 3; */
}

.titletext {
	position: absolute;
	line-height: 0;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 0;
	z-index: 4;

	pointer-events: none;
	
	color: rgb(240, 240, 240);
	/* mix-blend-mode: darken; */
	/* color: white; */
	font-family: 'Work Sans', sans-serif;
	font-size: calc(10vw + .4em);
	font-weight: 200;
	text-shadow:#000000 2px 2px;
	opacity: 0;

	text-align: center;
	vertical-align: middle;

	animation: fadeup 1000ms 700ms forwards;
}

@keyframes popout {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 1;
		text-shadow: rgba(0,0,0,1) 3px 3px;
	}
}
  
@keyframes fadeup {
	from {
		transform: translateY(2rem);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes slidedown {
	from {
		transform: translateY(-30%);
		opacity: 0.0;
	}

	to {
		transform: translateY(0%);
		opacity: 1.0;
	}
}

.nav-padding {
	height: 74px;
	/* background-color: black; */
}

