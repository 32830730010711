:root {
	--button-color: white;
	--button-bgcolor: rgba(50, 50, 50, 1);
}

.booking-button {
	border: solid black 2px;
	border-radius: 0;
	padding: 1rem 1.5rem;

	color: var(--button-color);
	background-color: var(--button-bgcolor);
	transition-property: color, background-color;
	transition-duration: 80ms;
	font-size: 1rem;

	animation: flash 500ms 1600ms ease 2;
}

.booking-button-lg {
	padding: 1.5rem 3rem;
	font-size: 1.5rem;

	margin-top: 3rem;
}

.booking-button:hover {
	color: var(--button-bgcolor);
	background-color: var(--button-color);
}

@keyframes flash {
	50% {
		color: var(--button-bgcolor);
		background-color: var(--button-color);
	}
	100% {
		color: var(--button-color);
		background-color: var(--button-bgcolor);
	}
}

.title-button-wrapper {
	z-index: 4;
	position: absolute;
	line-height: 0;
	width: 100%;
	/* height: 100%; */
	top: 75%;
	left: 0;
	/* margin: 0 auto; */
}

#bookingModal .modal-content {
	height: 93vh;
}

#bookingModal .modal-body {
	margin: 0;
	padding: 0;
}

#bookingModal iframe {
	width: 100%;
	height: 100%;
	border: medium none;
}

body.modal-open {
	padding-right: 0px !important;
	overflow-y: hidden;
}