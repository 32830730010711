.About > .container-fluid {
	padding: calc(1rem + 5vh) 6vw;
	min-height: 80vh;
	height: 100%;
	display: table;
	/* min-height: 800px; */
}

.about-text {
	font-size: 1.1rem;
	/* margin: 0 2rem; */
}

.about-bgimg-wrapper {
	padding: 0 2.5rem;
	height: 40vw;
	min-height: 350px;
	max-height: 520px;
}

.about-bgimg-1 {
	background-image: url(../images/IMG_2151.jpg);
	background-size: auto 135%;
	background-position: center 100%;
	height: 100%;

	max-width: 620px;
	margin: 0 auto;
}

.about-bgimg-2 {
	background-image: url(../images/IMG_2093.jpg);
	background-size: auto 200%;
	background-position: center 40%;
	height: 100%;
}