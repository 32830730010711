.Navbar {
	z-index: 10;
	/* min-height: 4vh; */
	position: fixed;
	width: 100%;

	background-color: rgba(255, 255, 255, 1);
	/* mix-blend-mode: difference; */

	top: 0;
	transition: top 0.28s ease;
	/* padding-left: 3vw; */
}

.navbar-brand, .nav-link {
	color: black;
	text-shadow: var(--hover-color) 0rem 0rem;
	transition-property: text-shadow, transform;
	transition-duration: 120ms;
	transition-timing-function: linear;
	/* transition: text-shadow 120ms linear, transform 120ms linear; */
}

.navbar-brand {
	font-size: 2rem;
	/* margin-right: 2em; */
}

.nav-link {
	font-weight: 400;
	/* margin: 0 0.5rem; */
}

.navbar-brand:hover, .nav-link:hover {
	/* color: rgb(255, 175, 0); */
	/* color: rgb(0, 80, 255); */

	color: black;
	text-shadow: var(--hover-color) 0.2rem 0.2rem;
	transform: translate(-0.2rem, -0.2rem);
	/* text-shadow: none; */
}

.Navbar .active {
	text-decoration: underline;
}