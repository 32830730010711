.Gallery .insta-header {
	padding: 2.5rem;
}

.insta-logo {
	background-image: url(../images/IG_Glyph_Fill_inverse.png);
	background-size: 10rem;
	background-repeat: no-repeat;
	background-position: center;
	opacity: 1;

	display: block;
	height: 10rem;
	width: 10rem;
	border-radius: 50px;
	transition: background-color 80ms;
}

.insta-logo:hover {
	background-color: #0056B3;
}

.Gallery .juicer-feed {
	height: 100%;
	overflow: visible;
	scrollbar-width: none;
	margin: 3rem;
}

@media only screen and (max-width: 768px) {
	.Gallery .juicer-feed {
		width: 100%;
	}
}

.Gallery .juicer-feed h1.referral {
	margin: 0;
}

.insta-handle {
	margin: .9rem;
	font-weight: bold;
	font-size: 1.2rem;
}
