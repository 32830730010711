.Services {
	padding: 3.2rem 0;
	background-image: url(../images/IMG_contact_edit.jpg);

	background-attachment: fixed;
	background-size: cover;
	background-position: 40% center;
}

.container-services {
	color: white;
	text-shadow: black 0 0 5px;
}

.table-services {
	color: white;
	font-size: 1.5rem;
	max-width: 500px;
	margin-left: 1rem;
}

.table-services td {
	padding: 0.2rem;
}

.table-services tr > td:nth-of-type(2) {
	/* text-align: right; */
	font-weight: bold;
}