:root {
	--ease-out-slow: cubic-bezier(0.19, 1, 0.22, 1);
	--hover-color: rgba(230, 196, 147, 0.75);
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Nunito", "Work Sans", "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}



html, body {
	background-color: rgb(250, 250, 250);

    max-width: 100%;
	overflow-x: hidden;
}

body::-webkit-scrollbar {
    width: .4rem;
}
body::-webkit-scrollbar-track {
    background-color: white;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
}
 
body::-webkit-scrollbar-thumb {
	background-color: gray;
	outline: 1px solid slategrey;
}

h2, h3, h4 {
	font-family: 'DM Serif Text', serif;

	position: relative;
	display: block;
	margin: 0;
	/* font-size: 4rem; */
	/* z-index: -1; */
	/* text-shadow: 0px 1px 1px rgba(255,255,255,1); */
}


/* body * {
	border: 1px solid black;
} */


/* GLOBAL CLASSES */

.text-title {
	font-family: 'Work Sans', sans-serif;
	font-weight: 200;
}

.text-title-bold {
	font-weight: 500;
}

.bold {
	font-weight: bold;
}

.italic {
	font-style: italic;
}

.shadow-box {
	box-shadow: -10px 10px 0px 0px rgba(0, 0, 0, 0.8);
}


.scrollarea {
	height: 70vh;
}


/* ANIMATIONS */

[data-aos] {
	transition: none;
}

/* HEADER ANIMATION */
[data-aos] .header-wrapper * {
	transition: transform 700ms cubic-bezier(0.165, 0.84, 0.44, 1);
	transform: translateY(6rem);
}

[data-aos].aos-animate .header-wrapper * {
	transform: translateY(0);
}

[data-aos] .header-wrapper {
	font-size: 0;
	position: relative;
	overflow: hidden;
	padding-bottom: 0.4rem;
}

[data-aos] .header-wrapper::after {
	position: absolute;
	content: '';
	height: 10px;
	background-color: currentColor;
	bottom: -0.4rem;

	transition: width 1000ms var(--ease-out-slow) 400ms;
	width: 0%;
}

[data-aos].aos-animate .header-wrapper::after {
	width: 100%;
}

/* FADE UP ANIMATION */
[data-aos] .fade-up {
	transition-property: opacity, transform;
	transition-duration: 1000ms;
	transition-timing-function: var(--ease-out-slow);
	opacity: 0;
	transform: translateY(2rem);
}
[data-aos] .fade-up:nth-of-type(2) {
	transition-delay: 100ms;
}
[data-aos] .fade-up:nth-of-type(3) {
	transition-delay: 200ms;
}

[data-aos].aos-animate .fade-up {
	opacity: 1;
	transform: translateY(0);
}